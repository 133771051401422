import React, { Component } from 'react';
import * as style from './socialShare.module.scss';
import iconFacebook from '../../assets/facebook.svg';
import iconTwitter from '../../assets/twitter.svg';
import translations from '../../utils/translations';
import ArticleThemeContext from '../../context/articleThemeContext';

const networks = {
  facebook: { width: 600, height: 300 },
  twitter: { width: 600, height: 254 },
};

const popup = (network, dest) => {
  const options = 'menubar=no,toolbar=no,resizable=yes,scrollbars=yes,';
  window.open(
    dest,
    '',
    options +
      'height=' +
      networks[network].height +
      ',width=' +
      networks[network].width
  );
};

class SocialShare extends Component {
  static contextType = ArticleThemeContext;

  constructor() {
    super();
    this.state = {
      open: false,
    };
  }
  toggleOpen = () => {
    this.setState({ open: !this.state.open });
  };
  render() {
    const facebookShare = () => {
      const urlToShare = encodeURIComponent(window.location.href);
      popup(
        'facebook',
        `https://www.facebook.com/sharer/sharer.php?u=${urlToShare}`
      );
    };

    const twitterShare = () => {
      const urlToShare = encodeURIComponent(window.location.href);
      popup(
        'twitter',
        `https://twitter.com/share?url=${urlToShare}&text=${
          this.props.title ? this.props.title : ''
        }&via=outriderfdn`
      );
    };

    const isLight = this.context === 'light';
    const type = isLight ? 'icon-circle-orange' : 'icon-circle';
    const shareIcon = isLight ? `${style.shareIconOrange} ${style.orange}` : style.shareIcon;
    const closeIcon = isLight ? `${style.closeIconOrange} ${style.orange}` : '';
    const label = isLight ? style.labelOrange : '';
    const shareLabel = isLight ? style.shareLabelOrange : style.shareLabel;
    let orientation = this.props.horizontal ? style.horizontal : style.vertical;

    if (isLight && this.props.horizontal) {
      orientation += ' ' + style.orange;
    }

    return (
      <div
        className={`${this.props.hidden ? style.socialHidden : style.social} ${
          this.props.static ? style.noFix : ''
        } ${orientation} ${
          this.state.open ? style.open : ''
        }`}
      >
        <button onClick={this.toggleOpen} className={style.shareButton}>
          <span className={`icon-share ${type} ${shareIcon}`} />
          <span className={`${type} icon-arrow ${style.closeIcon} ${closeIcon}`} />
          <span style={this.props.labelStyle ? this.props.labelStyle : {}} className={`${shareLabel} ${label}`}>{translations.t('Share')}</span>
        </button>
        <button
          onClick={facebookShare}
          className={`icon-social icon-facebook ${style.socialButton}`}
        >
          <span>{translations.t('Facebook Share')}</span>
        </button>
        <button
          onClick={twitterShare}
          className={`icon-social icon-twitter ${style.socialButton}`}
        >
          <span>{translations.t('Twitter Share')}</span>
        </button>
      </div>
    );
  }
}

export default SocialShare;
