// extracted by mini-css-extract-plugin
export var logo = "fellow-module--logo--NQre6";
export var spacer = "fellow-module--spacer--kpocz";
export var container = "fellow-module--container--6vUPE";
export var staffWrap = "fellow-module--staffWrap--l9p3H";
export var textCol = "fellow-module--textCol--O6MOD";
export var imageCol = "fellow-module--imageCol--yisud";
export var toOurTeamLink = "fellow-module--toOurTeamLink--lrSVY";
export var toOurTeamIcon = "fellow-module--toOurTeamIcon--7d6ju";
export var toOurTeamText = "fellow-module--toOurTeamText--Vk9PM";
export var staffName = "fellow-module--staffName--s6jUO";
export var staffPosition = "fellow-module--staffPosition--rxavC";
export var staffBio = "fellow-module--staffBio---+R+U";
export var socialWrap = "fellow-module--socialWrap--5rAOz";
export var socialItem = "fellow-module--socialItem--OwVVV";
export var bioImage = "fellow-module--bioImage--hVT8e";
export var bioImageCredit = "fellow-module--bioImageCredit--jPP81";
export var projectsCallout = "fellow-module--projectsCallout--SZME7";
export var projectsCalloutImage = "fellow-module--projectsCalloutImage--24G63";
export var projectsCalloutHeading = "fellow-module--projectsCalloutHeading--yQUX6";
export var projectsButtonWrap = "fellow-module--projectsButtonWrap--fPglX";
export var textLine = "fellow-module--textLine--OWYvL";
export var socialShareMobile = "fellow-module--socialShareMobile--Rtd5m";
export var sectionHeading = "fellow-module--sectionHeading--0BCAJ";
export var fellowshipStories = "fellow-module--fellowshipStories--8qg1C";
export var sectionInset = "fellow-module--sectionInset--fH5HJ";
export var sectionItem = "fellow-module--sectionItem--JJHli";
export var storyImg = "fellow-module--storyImg--6gtz8";
export var storyTitle = "fellow-module--storyTitle--omm6g";
export var angle = "fellow-module--angle--728Gf";
export var slideUp = "fellow-module--slideUp--RLKgA";
export var slideUpLarge = "fellow-module--slideUpLarge--86F6q";