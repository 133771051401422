// extracted by mini-css-extract-plugin
export var social = "socialShare-module--social--kRvPg";
export var noFix = "socialShare-module--noFix--f-chY";
export var closeIcon = "socialShare-module--closeIcon--yaaRO";
export var open = "socialShare-module--open--LSmqq";
export var shareButton = "socialShare-module--shareButton--zkFlp";
export var shareLabel = "socialShare-module--shareLabel--Tco02";
export var shareIcon = "socialShare-module--shareIcon--R0QQb";
export var socialButton = "socialShare-module--socialButton--MHYE6";
export var horizontal = "socialShare-module--horizontal--1s2uy";
export var orange = "socialShare-module--orange--F9YY+";
export var shareLabelOrange = "socialShare-module--shareLabelOrange--vs1C9";
export var labelOrange = "socialShare-module--labelOrange--7DAGP";
export var vertical = "socialShare-module--vertical--sXeSV";
export var shareIconOrange = "socialShare-module--shareIconOrange--LKUDV";
export var closeIconOrange = "socialShare-module--closeIconOrange--+zg0U";
export var socialHidden = "socialShare-module--socialHidden--XpnrK socialShare-module--social--kRvPg";
export var slideUp = "socialShare-module--slideUp---q80q";
export var slideUpLarge = "socialShare-module--slideUpLarge--cgKoq";